import axios from "axios";

import { baseUrl } from "services/client";

export async function fetchSubscriptionMeta() {
  const { data } = await axios.get(`${baseUrl}subscription-meta/`);
  return data;
}

export async function fetchSubscriptionCancellations() {
  const { data } = await axios.get(`${baseUrl}subscription-cancellations/`);
  return data;
}

export async function fetchAnnualRevenueRetention() {
  const { data } = await axios.get(`${baseUrl}annual-subscription-retentions/`);
  return data;
}
