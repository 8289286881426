import styled from "styled-components";

const Wrapper = styled.div`
  margin: -3rem;

  > div {
    padding: 3rem;
  }
`;

function DashboardSecondaryContainer(props) {
  /** Special container for when a dashboard section needs to remove the inherited padding. */
  return (
    <Wrapper>{props.children}</Wrapper>
  )
}

export default DashboardSecondaryContainer;
