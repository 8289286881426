import React from "react";
import { useParams } from "react-router";

function EventDetailRoute(props) {
  /** Display and manage data for a single event. */
  const { eventId } = useParams();
  return (
    <div>
      Event detail route for ... {eventId}
    </div>
  )
}

export default EventDetailRoute;
