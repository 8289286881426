import { string } from "prop-types";
import Form from 'react-bootstrap/Form';

import FormLabel from "../Label";


function FormGroup({ label, error, ...props}) {
  return (
    <Form.Group {...props}>
      { label && <FormLabel>{label}</FormLabel>}
      {props.children}
      {error && <Form.Control.Feedback type="invalid">{error.message}</Form.Control.Feedback>}
    </Form.Group>
  );
}

FormGroup.propTypes = {
  label: string,
  error: string,
}

FormGroup.defaultProps = {
  label: null,
  error: null,
}

export default FormGroup;
