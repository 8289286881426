import styled from "styled-components"

const StyledHeader = styled.h3`
  color: ${props => props.theme.primary};
`;

function ModalTitle(props) {
  /** Display title for a modal. */
  return (
    <StyledHeader className="text-center mb-4" {...props}>{props.children}</StyledHeader>
  )
}

export default ModalTitle
