export const theme = {
  lime: "#e2eb76",
  blue: "#2e30e5",
  indigo: "#676784",
  purple: "#807be4",
  pink: "#ff9fd4",
  red: "#f81958",
  orange: "#ffa87d",
  yellow: "#faff29",
  green: "#a8d29b",
  teal: "#e2eb76",
  cyan: "#3498db",
  dark: "#1e1010",

  primary: "#2e30e5",
  secondary: "#ff9fd4",
  warning: "#ffa87d",
  info: "#807be4",
  danger: "#f81958",

  fontSizeLg: "18px",

  fullHdBreakpoint: "1920px",
  smallPcBreakpoint: "1250px",
  tabletBreakpoint: "991px",
  smBreakpoint: "576px",
}
