import { object } from "prop-types";
import Image from "react-bootstrap/Image";

import ProjectImageMask from "images/projects/default.svg";
import styled from "styled-components";

const Wrapper = styled.div`
  cursor: pointer;
  transition: transform 0.1s ease;

  &:hover {
    transform: scale(1.05);
  }
`;

const StyledImage = styled(Image)`
  mask-image: url(${ProjectImageMask});
  mask-size: 100%;
  mask-repeat: round;
`;

function ClassTile({ project, ...props }) {
  /** Summary tile to represent a class. */
  return (
    <Wrapper {...props}>
      <StyledImage fluid width="1000" height="1440" src={project.image || ProjectImageMask} />
      <h4>{project.title}</h4>
    </Wrapper>
  )
}

ClassTile.propTypes = {
  /** The class project object being displayed. */
  project: object,
}

export default ClassTile;
