import styled from "styled-components";

const StyledContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

function HeaderContainer(props) {
  return (
    <StyledContainer {...props}>{props.children}</StyledContainer>
  )
}

export default HeaderContainer;
