import axios from "axios";

import { baseUrl } from "services/client";

/** Class Phases */
export async function createClassPhase({ payload }) {
  const { data } = await axios.post(`${baseUrl}class-phases/`, payload);
  return data;
}

export async function updateClassPhase({ id, payload }) {
  const { data } = await axios.patch(`${baseUrl}class-phases/${id}/`, payload);
  return data;
}

export async function deleteClassPhase({ id }) {
  const { data } = await axios.delete(`${baseUrl}class-phases/${id}/`);
  return data;
}

