import { format } from 'date-fns-tz'

export function toCurrency(amount) {
  /** Convert a stripe amount to be displayed as currency. */
  const amountString = amount.toLocaleString("en-US", {
    maximumFractionDigits: 2,
  });

  return `$${amountString}`;
}

export const appUrl = process.env.REACT_APP_BUILD_ENV === 'prod' ?
  "https://www.projectcity.tv/" :
  "https://projectcity-admin.theclientdemos.com/";


export function formatDateTime(datetime, _format='MM/dd/yyyy') {
  return datetime && format(new Date(datetime), _format)
}

export function secondsToTime(seconds) {
  /** Turn an integer of seconds into a string formatted hh:mm:ss */
  const h = Math.floor(seconds / 3600)
      .toString()
      .padStart(2, "0"),
    m = Math.floor((seconds % 3600) / 60)
      .toString()
      .padStart(2, "0"),
    s = Math.floor(seconds % 60)
      .toString()
      .padStart(2, "0");

  return `${h}:${m}:${s}`;
}
