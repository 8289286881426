import StatSection from "components/containers/StatSection";
import LoadingIcon from "components/loading/Icon";
import { fetchCurrentMonthActionSummary } from "features/tokens/service";
import { fetchDonationMonthlyHistory } from "features/donations/service";
import { useQuery } from "react-query";
import styled from "styled-components";
import DonationMonthlyHistoryTable from "features/donations/tables/DonationMonthlyHistoryTable";

const StatContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
`;

function ContentUploadMonthlyDonations() {
  /** Show monthly data for aggregates of all donations. */

  const { data, isLoading } = useQuery('tokens-current-month', fetchCurrentMonthActionSummary);
  const { data: monthlyData, isLoading: monthlyIsLoading } = useQuery(
    'monthly-donation-meta',
    fetchDonationMonthlyHistory
  );

  return isLoading || monthlyIsLoading ? <LoadingIcon /> : (
    <div>
      <StatContainer>
        <StatSection top={data.gifted} bottom="Tokens Gifted This Month" />
        <StatSection top={data.available} bottom="Tokens Available" />
        <StatSection top={data.purchased} bottom="Tokens Purchased This Month" />
      </StatContainer>

      <div className="m-5">
        <DonationMonthlyHistoryTable data={monthlyData.data} />
      </div>
    </div>
  )
}

export default ContentUploadMonthlyDonations;
