import Navbar from "react-bootstrap/Navbar";

function SecondaryNavbar(props) {
  /** A reusable secondary horizontal navigation bar. */
  return (
    <Navbar bg="warning" expand="lg" variant="light">
      {props.children}
    </Navbar>
  )
}

export default SecondaryNavbar;
