import { string } from "prop-types";
import ReactModal from "react-modal";

import ModalTitle from "../Title";

function BaseModal({ title, ...props }) {
  /** Base modal component for all other modals to inherit from. */

  return (
    <ReactModal {...props}>
      {title && <ModalTitle>{title}</ModalTitle>}
      {props.children}
    </ReactModal>
  )
}

BaseModal.propTypes = {
  /** Title text to show on the modal. */
  title: string,
}

BaseModal.defaultProps = {
  title: null,
}

export default BaseModal;
