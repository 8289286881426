import BaseTable from "components/tables/Base";
import { formatDateTime } from "utils/general";

function TierSummaryTable({ data }) {
  /** Display summary data for registrationTiers */
  function renderRows() {
    return data.map(tier => {
      const { id, title, price, registrationStartDate, registrationEndDate, isVisible } = tier;

      return (
        <tr key={id}>
          <td>{title}</td>
          <td>${price}</td>
          <td>{formatDateTime(registrationStartDate)}</td>
          <td>{formatDateTime(registrationEndDate)}</td>
          <td>{isVisible ? <span>Yes</span> : <span className="text-muted">No</span>}</td>
        </tr>
      )
    })
  }

  return (
    <BaseTable>
      <thead>
        <tr>
          <th>title</th>
          <th>price</th>
          <th>start date</th>
          <th>end date</th>
          <th>visible</th>
        </tr>
      </thead>
      <tbody>
        {renderRows()}
      </tbody>
    </BaseTable>
  )
}

export default TierSummaryTable;
