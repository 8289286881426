import axios from "axios";

import { baseUrl } from "services/client";

export async function loginUser(payload) {
  return await axios.post(`${baseUrl}auth/login/`, payload);
}

export async function verifyToken(payload) {
  return await axios.post(`${baseUrl}auth/token/verify/`, payload);
}
