import { func } from "prop-types";
import styled from "styled-components";

const StyledHeader = styled.th`
  cursor: pointer;
  user-select: none;
`;

function SortableHeader(props) {
  /** Table header used on sortable tables. */
  return (
    <StyledHeader {...props}>{props.children}</StyledHeader>
  )
}

SortableHeader.propTypes = {
  /** Click event to toggle the sorting. */
  onClick: func.isRequired,
}

export default SortableHeader;
