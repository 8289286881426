import { useQuery } from "react-query";
import styled from "styled-components";

import DashboardSecondaryContainer from "components/containers/DashboardSecondary";
import LoadingIcon from "components/loading/Icon";
import SubscriptionLineChart from "features/subscriptions/charts/Active";
import { fetchSubscriptionMeta } from "features/subscriptions/service";
import SubscriptionTrendTable from "features/subscriptions/tables/Trend";
import SubscriptionSecondaryNavbar from "features/subscriptions/navigation/Secondary";
import { toCurrency } from "utils/general";

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

function SubscriptionsRoute() {
  /** Main route for subscriptions showing general meta data. */
  const { data, isLoading } = useQuery('subscription', fetchSubscriptionMeta);

  if (isLoading)
    return <LoadingIcon />

  const { results } = data;

  const reversed = [...results].reverse();

  return (
    <DashboardSecondaryContainer>
      <SubscriptionSecondaryNavbar />
      <div className="p-5">
        <HeaderContainer>
          <SubscriptionLineChart data={reversed} />
          <div>
            <h3>Current MRR: <b>{reversed[0] && toCurrency(reversed[0].active * 33)}</b></h3>
            <h3 className="text-muted">Monthly Churn Rate: <b>X%</b></h3>
            <h3 className="text-muted">MRR Monthly Change: <b>X%</b></h3>
          </div>
        </HeaderContainer>
        <SubscriptionTrendTable data={results} />
      </div>
    </DashboardSecondaryContainer>
  )
}

export default SubscriptionsRoute;
