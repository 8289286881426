import axios from "axios";

import { baseUrl } from "services/client";

export async function fetchContentUploads() {
  return await axios.get(`${baseUrl}content-uploads/stats/`);
}

export async function fetchAnimaticDonations() {
  return await axios.get(`${baseUrl}content-uploads/donation_meta/`);
}


