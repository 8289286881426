import styled from "styled-components";

import PageHeader from "components/text/PageHeader";
import LoginForm from "features/authentication/forms/Login";

const FormContainer = styled.div`
  width: 50%;
  margin-left: 25%;
`;

function LoginRoute() {
  /** Route to show the login form, which is required to access the site. */
  return (
    <div className="m-5">
      <div className="text-center">
        <PageHeader>Login</PageHeader>
      </div>
      <FormContainer>
        <LoginForm />
      </FormContainer>
    </div>
  )
}

export default LoginRoute;
