import { arrayOf, object } from "prop-types";
import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import styled from "styled-components";

import ConfirmationModal from "components/modals/Confirmation";
import ClassPhaseModal from "features/classphases/modals/Phase";
import { deleteClassPhase } from "features/classphases/service";

const Wrapper = styled.div`
  cursor: pointer;

  &:hover {
    color: ${props => props.theme.primary};

    span {
      color: ${props => props.theme.primary}!important;
    }
  }
`;

function ClassPhases({ classPhases }) {
  /** Display the phases for a given class project. */

  const [classPhaseModalOpen, setClassPhaseModalOpen] = useState(false);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [modalData, setModalData] = useState(null);
  const queryClient = useQueryClient()

  const { mutate: deleteMutation, isLoading: deleteIsLoading } = useMutation(deleteClassPhase, {
    onSuccess(data, variables) {
      /** Remove the deleted item from phase list. */
      const classId = modalData.project.toString();
      const classData = queryClient.getQueryData(['classes', classId]);
      let classPhases = classData.classPhases.filter(classPhase => {
        return classPhase.id !== variables.id;
      });

      queryClient.setQueryData(['classes', classId], {
        ...classData,
        classPhases,
      });
      setDeleteConfirmationOpen(false);
    }
  })

  function openModal(classPhase) {
    setModalData(classPhase);
    setClassPhaseModalOpen(true);
  }

  function renderPhases() {
    return classPhases.map(classPhase => {
      const startDate = new Date(classPhase.startDate);
      return (
        <Wrapper key={classPhase.id} onClick={() => openModal(classPhase)}>
          <p><span className="text-muted">{startDate.toLocaleDateString("en-US")}</span> {classPhase.title}</p>
        </Wrapper>
      )
    })
  }

  return (
    <>
      {renderPhases()}
      <ClassPhaseModal
        isOpen={classPhaseModalOpen}
        onRequestClose={() => setClassPhaseModalOpen(false)}
        showDeleteConfirmationModal={() => {
          setClassPhaseModalOpen(false);
          setDeleteConfirmationOpen(true);
        }}
        classPhase={modalData}
        title="Update class phase"
      />

      <ConfirmationModal
        title="Delete class phase?"
        isOpen={deleteConfirmationOpen}
        onRequestClose={() => setDeleteConfirmationOpen(false)}
        action={() => deleteMutation({ id: modalData.id })}
        isLoading={deleteIsLoading}
      >
        <p>Are you sure you want to delete this class phase for "{modalData?.title}"?</p>
      </ConfirmationModal>
    </>
  )
}

ClassPhases.propTypes = {
  /** Data for the phases to render. */
  classPhases: arrayOf(object).isRequired,
}

export default ClassPhases;
