import { arrayOf, object } from 'prop-types';

import BaseTable from 'components/tables/Base';
import SortableHeader from 'components/tables/SortableHeader';
import useSortableData from 'hooks/SortableData';

function DonationMonthlyHistoryTable({ data }) {
  /** Table of data showing the history of donation monthly meta statistics. */

  console.log('reci', data)

  const { items: historyRecords, requestSort } = useSortableData(data);

  function renderRows() {
    return historyRecords.map(historyRecord => {
      /** Render a row for each date in the monthly history. */

      return (
        <tr key={historyRecord.id}>
          <td>{new Date(historyRecord.date).toLocaleDateString("en-US", {timeZone: 'UTC'})}</td>
          <td>{historyRecord.totalRevenue}</td>
          <td>{historyRecord.totalTokensDonated}</td>
          <td>{historyRecord.totalTokensPurchased}</td>
          <td>{parseInt(historyRecord.userAllocationPercent * 100)}%</td>
        </tr>
      )
    })
  }

  return (
    <BaseTable>
      <thead>
        <tr>
          <SortableHeader onClick={() => requestSort('date')}>Date</SortableHeader>
          <SortableHeader onClick={() => requestSort('totalRevenue')}>Total Revenue</SortableHeader>
          <SortableHeader onClick={() => requestSort('totalTokensDonated')}>Tokens Donated</SortableHeader>
          <SortableHeader onClick={() => requestSort('totalTokensPurchased')}>Tokens Purchased</SortableHeader>
          <SortableHeader onClick={() => requestSort('userAllocationPercent')}>Allocation Percent</SortableHeader>
        </tr>
      </thead>
      <tbody>
        {renderRows()}
      </tbody>
    </BaseTable>
  )
}

DonationMonthlyHistoryTable.propTypes = {
  /** History data that will be rendered. */
  data: arrayOf(object),
}

export default DonationMonthlyHistoryTable;
