import { number, string } from "prop-types";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.theme.purple};
  color: ${props => props.theme.dark};
  padding: 3rem;
  margin: 3rem;
  border-radius: 20px;
  text-align: center;
`;

const Number = styled.div`
  font-size: 2.5rem;
  font-weight: bold;
`;

const Description = styled.div`
  font-size: 1.2rem;
`;


function StatSection({ top, bottom }) {
  /** Show some statistic, generally with a number and a description */

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  return (
    <Wrapper>
      <Number>{numberWithCommas(top)}</Number>
      <Description>{bottom}</Description>
    </Wrapper>
  )
}

StatSection.propTypes = {
  top: number.isRequired,
  bottom: string.isRequired,
}

export default StatSection;
