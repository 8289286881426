import { useContext } from "react";
import { useLocation } from "react-router-dom";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Image from "react-bootstrap/Image";
import Container from "react-bootstrap/Container";
import { Link } from "react-router-dom";
import styled from "styled-components";

import Button from "components/buttons/Base";
import AuthContext from "context/Auth";
import ProjectCityLogo from "images/logos/stacked.svg"

const StyledNavLink = styled(Nav.Link)`
  padding: 10px;
  text-decoration: none;
  color: ${props => props.theme.dark};
  font-size: 18px;


  &:hover {
    color: ${props => props.theme.secondary};
  }

  ${props => props.$active && `
    text-decoration: underline;
    text-underline-offset: 5px;
    text-decoration-thickness: 2px;
  `}
`;

function DashboardNavbar() {
  /** Main dashboard navbar. */

  const { setAuthContext } = useContext(AuthContext);
  const location = useLocation();

  function handleLogout() {
    setAuthContext({
      accessToken: "",
      refreshToken: "",
      isAuthenticated: false,
    });
  }

  const linkData = [
    {
      to: "/subscriptions",
      label: "Subscriptions",
    },
    {
      to: "/classes",
      label: "Classes",
    },
    {
      to: "/events",
      label: "Events",
    },
    {
      to: "/content-uploads",
      label: "Content Uploads",
    },
  ];

  return (
    <Navbar bg="info" expand="lg" variant="light">
      <Container fluid>
        <Navbar.Brand><Image fluid src={ProjectCityLogo} /></Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            {linkData.map(link => {
              const { label } = link;
              return <StyledNavLink key={label} as={Link} $active={location.pathname.startsWith(link.to)} {...link}>{label}</StyledNavLink>
            })}
          </Nav>
          <Nav className="ms-auto">
            <Nav.Link as={Button} onClick={handleLogout}>Logout</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}

export default DashboardNavbar;
