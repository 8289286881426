import { number, shape, string } from "prop-types";

import BaseTable from "components/tables/Base";

function SubscriptionTrendTable({ data, ...props }) {
  /** Show raw data of our subscription trend data. */

  return (
    <BaseTable {...props}>
      <thead>
        <tr>
          <th>Date</th>
          <th>Active</th>
          <th>Pending</th>
          <th>Churn</th>
        </tr>
      </thead>
      <tbody>
        {data.map(date => (
          <tr key={date.date}>
            <td>{date.date}</td>
            <td>{date.active}</td>
            <td>{date.pendingCancellation}</td>
            <td>{date.churn}</td>
          </tr>
        ))}
      </tbody>
    </BaseTable>
  )
}

SubscriptionTrendTable.propTypes = {
  data: shape({
    date: string.isRequired,
    active: number.isRequired,
    pendingCancellation: number.isRequired,
    churn: number.isRequired,
  })
}

export default SubscriptionTrendTable;
