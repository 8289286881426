import Form from 'react-bootstrap/Form';
import styled from "styled-components";

const StyledForm = styled(Form)`
  background-color: GhostWhite;
`;

function BaseForm({ className, ...props }) {
  /** A base form that all other forms should inherit from. */
  return (
    <StyledForm className={`border rounded p-3 ${props.className}`} {...props}>
      {props.children}
    </StyledForm>
  );
}

BaseForm.defaultProps = {
  className: "",
}

export default BaseForm;
