import { Table } from "react-bootstrap";

function BaseTable(props) {
  /** A shared table component for all tables to use. */
  return (
    <Table {...props}>
      {props.children}
    </Table>
  )
}

export default BaseTable;
