import Nav from "react-bootstrap/Nav";
import { Link } from "react-router-dom";

import SecondaryNavbar from "components/navigation/Secondary";

function SubscriptionSecondaryNavbar() {
  /** A reusable secondary horizontal navigation bar. */
  return (
    <SecondaryNavbar>
      <Nav className="m-auto">
        <Nav.Link as={Link} to="/subscriptions">Trend</Nav.Link>
        <Nav.Link as={Link} to="/subscriptions/cancellations">Cancellations</Nav.Link>
        <Nav.Link as={Link} to="/subscriptions/revenue-retention">Revenue Retention</Nav.Link>
      </Nav>
    </SecondaryNavbar>
  )
}

export default SubscriptionSecondaryNavbar;
