import { useQuery } from "react-query";

import PageHeader from "components/text/PageHeader";
import LoadingIcon from "components/loading/Icon";
import { fetchContentUploads } from "features/contentuploads/service";
import ContentUploadTable from "features/contentuploads/tables/ContentUploads";

function ContentUploadListRoute() {
  /** Display and manage data for content uploads. */

  const { data: response, isLoading } = useQuery('contentuploads', fetchContentUploads);

  if (isLoading) return <LoadingIcon />

  return (
    <>
      <PageHeader>Content uploads</PageHeader>
      <ContentUploadTable data={response.data} />
    </>
  )
}

export default ContentUploadListRoute;
