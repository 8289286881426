import Alert from "react-bootstrap/Alert";
import styled from "styled-components";

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

function FormSubmitContainer({ errorMessage, ...props }) {
  /** Standard container to house the submit section of a form. */
  return (
    <div className="mt-3">
      {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
      <ButtonWrapper className={`${ props.children.length === 1 ? "text-end" : ""} w-100`}>
        {props.children}
      </ButtonWrapper>
    </div>
  )
}

export default FormSubmitContainer;
