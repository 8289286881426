import { func, object, string } from "prop-types";

import BaseModal from "components/modals/Base";
import ClassPhaseForm from "features/classphases/forms/ClassPhase";

function ClassPhaseModal({ title, classPhase, showDeleteConfirmationModal, ...props }) {
  /** Show a modal to create or modify a class phase object. */

  // Need to clean the class phase data before passing it into form.
  const cleanedClassPhase = !classPhase ? null : {
    ...classPhase,
    startDate: new Date(classPhase.startDate),
    endDate: new Date(classPhase.endDate),
  }

  return (
    <BaseModal
      { ...props }
      title={title}
    >
      <ClassPhaseForm
        closeModal={props.onRequestClose}
        classPhase={cleanedClassPhase}
        showDeleteConfirmationModal={showDeleteConfirmationModal}
      />
    </BaseModal>
  )
}

ClassPhaseModal.propTypes = {
  /** The class phase object to modify. */
  classPhase: object,

  /** Title text to show on the modal. */
  title: string,

  /** Close the update modal and open the modal to confirm deletion. */
  showDeleteConfirmationModal: func,
}

ClassPhaseModal.defaultProps = {
  classPhase: null,
  title: null,
  showDeleteConfirmationModal: () => {},
}

export default ClassPhaseModal;
