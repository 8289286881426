import { useContext, useEffect } from "react";
import { useNavigate, Outlet } from "react-router-dom";
import { useMutation } from "react-query";

import DashboardNavbar from "components/navigation/DashboardNavbar";
import AuthContext from "context/Auth";
import { verifyToken } from "features/authentication/service";
import useLogoutUser from "features/authentication/hooks/logout";

function DashboardBaseRoute(props) {
  /** Provide the base route for all dashboard routes. */

  const navigate = useNavigate();

  const { authContext } = useContext(AuthContext);
  const logoutUser = useLogoutUser();

  const {
    mutate: verifyTokenMutation,
    isError,
    isLoading,
  } = useMutation(payload => verifyToken(payload));

  useEffect(() => {
    if (!authContext?.isAuthenticated)
      navigate('/login');
    else if (!isLoading) {
      verifyTokenMutation({ token: authContext.accessToken});
    }
  }, [])

  useEffect(() => {
    if (isError) {
      logoutUser();
    }
  }, [isError, logoutUser])

  return (
    <div>
      <DashboardNavbar />
      <div className="p-5">
        <Outlet />
      </div>
    </div>
  )
}

export default DashboardBaseRoute;
