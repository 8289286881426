export function handleResponseError(data, setFormError, setFieldError) {
  /**
   * Check the data and set the form error appropriately.
   *
   * This is the handler used for rtk-query mutations, however if there is an action not using
   * rtk-query it generally uses just `setFieldErrors`.
   *
   * setFormError: Set a general form error which isn't associated with an individual field.
   * setFieldError: Set an individual field error.
   */

  if (data?.detail) setFormError(data.detail);

  for (let fieldKey in data) {
    setFieldError(fieldKey, {
      type: "manual",
      message: data[fieldKey],
    });
  }
}
