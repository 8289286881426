import { useInfiniteQuery } from 'react-query'
import { useNavigate } from 'react-router';
import styled from 'styled-components';

import CustomInfiniteScroll from 'components/misc/InfiniteScroll';
import PageHeader from 'components/text/PageHeader';
import LoadingIcon from 'components/loading/Icon';
import { fetchClasses } from 'features/classes/service';
import ClassTile from 'features/classes/components/Tile';

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 20px;
  padding: 20px;
`;

function ClassListRoute() {
  /** Display and manage data for a single event. */
  const navigate = useNavigate()

  const { data, isLoading, fetchNextPage, hasNextPage } = useInfiniteQuery('classes', fetchClasses, {
    getNextPageParam: (lastPage) => {
      return lastPage.next
    },
  });

  if (isLoading) return <LoadingIcon />

  const totalLength = data.pages.reduce((partialSum, page) => partialSum + page.results.length, 0)

  return (
    <div>
      <PageHeader>Classes</PageHeader>
      <CustomInfiniteScroll
        dataLength={totalLength} //This is important field to render the next data
        next={fetchNextPage}
        hasMore={hasNextPage}
        loader={<LoadingIcon />}
      >
        <Grid>
          {data.pages.map(page => {
            return page.results.map(_class => {
              function handleProjectClick() {
                navigate(`/classes/${_class.id}`)
              }

              return <ClassTile key={_class.id} project={_class} onClick={handleProjectClick} />
            })
          })}
        </Grid>
      </CustomInfiniteScroll>
    </div>
  )
}

export default ClassListRoute;
