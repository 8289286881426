import { arrayOf, object } from 'prop-types';

import BaseTable from 'components/tables/Base';
import SortableHeader from 'components/tables/SortableHeader';
import TableLink from 'components/tables/Link';
import { appUrl } from 'utils/general';
import useSortableData from 'hooks/SortableData';

function AnimaticDonationTable({ data }) {
  /** Table of data for animatic donations. */

  const { items: contentUploads, requestSort } = useSortableData(data)

  function renderRows() {
    return contentUploads.map(contentUpload => {
      /** Render a row for each content upload. */
      const href = `${appUrl}sprint/content/${contentUpload.id}/${contentUpload.slug}`

      return (
        <tr key={contentUpload.id}>
          <td><TableLink href={href}>{contentUpload.id}</TableLink></td>
          <td>{contentUpload.title}</td>
          <td>{contentUpload.createdBy.fullName} (@{contentUpload.createdBy.username})</td>
          <td className="text-end">{contentUpload.tokensReceived}</td>
          <td className="text-end">{contentUpload.tokensPurchased}</td>
        </tr>
      )
    })
  }

  return (
    <BaseTable>
      <thead>
        <tr>
          <SortableHeader onClick={() => requestSort('id')}>Id</SortableHeader>
          <SortableHeader onClick={() => requestSort('title')}>Title</SortableHeader>
          <SortableHeader onClick={() => requestSort('title')}>Created by</SortableHeader>
          <SortableHeader onClick={() => requestSort('tokensReceived')} className="text-end">
            Tokens Received
          </SortableHeader>
          <SortableHeader onClick={() => requestSort('tokensReceived')} className="text-end">
            Tokens Purchased
          </SortableHeader>
        </tr>
      </thead>
      <tbody>
        {renderRows()}
      </tbody>
    </BaseTable>
  )
}

AnimaticDonationTable.propTypes = {
  /** Animatic data that will be rendered. */
  data: arrayOf(object),
}

export default AnimaticDonationTable;
