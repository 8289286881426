import { arrayOf, object } from 'prop-types';

import BaseTable from 'components/tables/Base';
import SortableHeader from 'components/tables/SortableHeader';
import TableLink from 'components/tables/Link';
import useSortableData from 'hooks/SortableData';
import { appUrl, secondsToTime } from 'utils/general';

function ContentUploadTable({ data }) {
  /** Table of data for content upload stats. */

  const { items: contentUploads, requestSort } = useSortableData(
    data.map(contentUpload => {
      /** Prepare the data by adding calculated fields. */
      const averageWatchTime = Math.round(contentUpload.totalWatchTime / contentUpload.totalPlays) || 0
      const averageWatchTimePercent = averageWatchTime === 0 ? 0 :
        Math.round(averageWatchTime / contentUpload.duration * 100)

      return { ...contentUpload, averageWatchTime, averageWatchTimePercent }
    })
  );

  function renderRows() {
    return contentUploads.map(contentUpload => {
      /** Render a row for each content upload. */
      const href = `${appUrl}sprint/content/${contentUpload.id}/${contentUpload.slug}`

      return (
        <tr>
          <td><TableLink href={href}>{contentUpload.id}</TableLink></td>
          <td>{contentUpload.title}</td>
          <td>{secondsToTime(contentUpload.duration)}</td>
          <td>{secondsToTime(contentUpload.monthlyWatchTime)}</td>
          <td>{secondsToTime(contentUpload.totalWatchTime)}</td>
          <td>{secondsToTime(contentUpload.averageWatchTime)}</td>
          <td>{contentUpload.averageWatchTimePercent}%</td>
          <td>{contentUpload.monthlyPlays}</td>
          <td>{contentUpload.totalPlays}</td>
        </tr>
      )
    })
  }

  return (
    <BaseTable>
      <thead>
        <tr>
          <SortableHeader onClick={() => requestSort('id')}>Id</SortableHeader>
          <SortableHeader onClick={() => requestSort('title')}>Title</SortableHeader>
          <SortableHeader onClick={() => requestSort('duration')}>Duration</SortableHeader>
          <SortableHeader onClick={() => requestSort('monthlyWatchTime')}>Past 30 Day WT</SortableHeader>
          <SortableHeader onClick={() => requestSort('totalWatchTime')}>Total WT</SortableHeader>
          <SortableHeader onClick={() => requestSort('averageWatchTime')}>Average WT</SortableHeader>
          <SortableHeader onClick={() => requestSort('averageWatchTimePercent')}>Average WT %</SortableHeader>
          <SortableHeader onClick={() => requestSort('monthlyPlays')}>Past 30 Day Plays</SortableHeader>
          <SortableHeader onClick={() => requestSort('totalPlays')}>Total Plays</SortableHeader>
        </tr>
      </thead>
      <tbody>
        {renderRows()}
      </tbody>
    </BaseTable>
  )
}

ContentUploadTable.propTypes = {
  /** Content upload data that will be rendered. */
  data: arrayOf(object),
}

export default ContentUploadTable;
