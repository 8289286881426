import axios from "axios";

import { projectType } from "features/classes/enums";
import { baseUrl } from "services/client";


/** Classes */
export async function fetchClasses({ pageParam }) {
  /** Either fetch the given next url or the default. */
  const fetchUrl = pageParam || `${baseUrl}projects/?type=${projectType.defaultClass}&ordering=order`
  const { data } = await axios.get(fetchUrl);
  return data;
}

export async function fetchClass(classId) {
  const { data } = await axios.get(`${baseUrl}projects/${classId}/`);
  return data;
}

export async function fetchClassEarnings(classId) {
  const { data } = await axios.get(`${baseUrl}projects/${classId}/earnings/`);
  return data;
}

