import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";

import Dashboard from "routes/Dashboard/Base";
import Events from "routes/Events/List";
import Event from "routes/Events/Detail";
import Classes from "routes/Classes/List";
import ClassDetail from "routes/Classes/Detail";
import ContentUploadsBase from "routes/ContentUploads/Base";
import ContentUploads from "routes/ContentUploads/List";
import ContentUploadDonations from "routes/ContentUploads/Donations/List";
import ContentUploadMonthlyDonations from "routes/ContentUploads/Monthly/List";
import ContentUpload from "routes/ContentUploads/Detail";
import Login from "routes/Login";

import Subscriptions from "routes/Subscriptions/Main";
import SubscriptionCancellations from "routes/Subscriptions/Cancellations";
import RevenueRetentionRoute from "routes/Subscriptions/RevenueRetention";

function BaseRouter() {
  /** Main router for the entire application. */
  return (
    <BrowserRouter>
      <Routes>
        <Route path="login" element={<Login />} />
        <Route path="/" element={<Dashboard />}>
          <Route index element={<Classes />} />
          <Route path="classes" index element={<Classes />} />
          <Route path="classes/:classId" element={<ClassDetail />} />
          <Route path="subscriptions">
            <Route index element={<Subscriptions />} />
            <Route path="cancellations" element={<SubscriptionCancellations />} />
            <Route path="revenue-retention" element={<RevenueRetentionRoute />} />
          </Route>
          <Route path="content-uploads" element={<ContentUploadsBase />}>
            <Route index element={<ContentUploads />} />
            <Route path="donations" element={<ContentUploadDonations />} />
            <Route path="monthly" element={<ContentUploadMonthlyDonations />} />
            <Route path=":contentUploadId" element={<ContentUpload />} />
          </Route>
          <Route path="events" element={<Events />} />
          <Route path="events/:eventId" element={<Event />} />
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export default BaseRouter
