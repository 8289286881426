import Nav from "react-bootstrap/Nav";
import { Link } from "react-router-dom";

import SecondaryNavbar from "components/navigation/Secondary";

function ContentUploadsSecondaryNavigation() {
  /** Secondary navigation to show in the content uploads section. */
  return (
    <SecondaryNavbar>
      <Nav className="m-auto">
        <Nav.Link as={Link} to="/content-uploads">Stats</Nav.Link>
        <Nav.Link as={Link} to="/content-uploads/donations">Animatic Donations</Nav.Link>
        <Nav.Link as={Link} to="/content-uploads/monthly">Monthly Donations</Nav.Link>
      </Nav>
    </SecondaryNavbar>
  )
}

export default ContentUploadsSecondaryNavigation;
