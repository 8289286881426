import { bool, func } from "prop-types";
import styled from "styled-components";

import FormSubmitContainer from "components/forms/SubmitContainer";
import FormSubmitButton from "components/buttons/FormSubmit";
import Button from "components/buttons/Base";
import BaseModal from "../Base";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100% - 58px);
`;

function ConfirmationModal({ action, isLoading, ...props }) {
  /** Confirm an action, usually to confirm deletion. */
  return (
    <BaseModal
      {...props}
    >
      <Wrapper>
        {props.children}
        <FormSubmitContainer>
          <Button variant="primary" onClick={props.onRequestClose}>Cancel</Button>
          <FormSubmitButton
            variant="link"
            className="text-danger"
            onClick={action}
            disabled={isLoading}
          >
            Yes, delete
          </FormSubmitButton>
        </FormSubmitContainer>
      </Wrapper>
    </BaseModal>
  )
}

ConfirmationModal.propTypes =  {
  /** Action to send if user confirms. */
  action: func.isRequired,

  /** Show if the action is currently loading. */
  isLoading: bool.isRequired,
}

export default ConfirmationModal;
