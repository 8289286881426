import InfiniteScroll from "react-infinite-scroll-component"
import styled from "styled-components"

const StyledInfiniteScroll = styled(InfiniteScroll)`
  overflow: hidden!important;
`;

function CustomInfiniteScroll(props) {
  return <StyledInfiniteScroll {...props}>{props.children}</StyledInfiniteScroll>
}

export default CustomInfiniteScroll;
