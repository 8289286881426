import { Outlet } from "react-router-dom";

import ContentUploadsSecondaryNavigation from "features/contentuploads/navigation/Secondary";
import DashboardSecondaryContainer from "components/containers/DashboardSecondary";


function ContentUploadsBase(props) {
  /** Base route for the content uploads. */
  return (
    <div className="content-upload-base">
      <DashboardSecondaryContainer>
        <ContentUploadsSecondaryNavigation />
        <div className="p-5">
          <Outlet />
        </div>
      </DashboardSecondaryContainer>
    </div>
  )
}

export default ContentUploadsBase;
