import axios from "axios";
import { useEffect, useMemo, useState } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { ThemeProvider } from "styled-components";

import AuthContext from "context/Auth";
import Router from "routes/Base";
import { theme } from "styles/theme";
import LoadingIcon from "components/loading/Icon";

const queryClient = new QueryClient()

function App() {
  const [authContext, setAuthContext] = useState(() => JSON.parse(window.localStorage.getItem('authContext')));
  const [isReady, setIsReady] = useState(false);


  const authContextValue = useMemo(
    () => ({
      authContext,
      setAuthContext: (value) => {
        setAuthContext(value);
        window.localStorage.setItem('authContext', JSON.stringify(value));
      }
    }),
    [authContext]
  );

  useEffect(() => {
    if (authContext?.accessToken?.length > 0) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${authContext.accessToken}`;
    }

    setIsReady(true);
  }, [authContext])

  if (!isReady) return <LoadingIcon />

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <AuthContext.Provider value={authContextValue}>
          <QueryClientProvider client={queryClient}>
            <Router />
          </QueryClientProvider>
        </AuthContext.Provider>
      </ThemeProvider>
    </div>
  );
}

export default App;
