import { useMutation } from "react-query";
import { Controller, useForm } from "react-hook-form";

import FormControl from "components/forms/Control";
import FormGroup from "components/forms/Group";
import BaseForm from "components/forms/Base";
import FormSubmitContainer from "components/forms/SubmitContainer";
import FormSubmitButton from "components/buttons/FormSubmit";
import { loginUser } from "features/authentication/service";
import { getResponseErrorMessage } from "utils/services";
import { useContext, useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router";
import AuthContext from "context/Auth";
import { checkPermissions } from "features/authentication/utils";
import { appPermission } from "features/authentication/enums";

function LoginForm() {
  /** Form used for users to authenticate with the application. */

  const { control, handleSubmit, formState: { errors } } = useForm();
  const navigate = useNavigate();
  const [customErrorMessage, setCustomErrorMessage] = useState(null);

  const { mutate, isError, error, isLoading, data } = useMutation(payload => loginUser(payload));
  const { setAuthContext } = useContext(AuthContext);

  const errorMessage = getResponseErrorMessage(error);

  useEffect(() => {
    /** Handle if the user has successfully logged in. */
    if (data?.data?.accessToken) {
      // Only allow project city admins past here.
      const { appPermissions } = data.data?.user;
      if (checkPermissions(appPermissions, [appPermission.projectCityAdmin, appPermission.adminAccess])) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${data.data.accessToken}`;
        navigate("/classes");
        setAuthContext({
          accessToken: data.data.accessToken,
          refreshToken: data.data.refreshToken,
          isAuthenticated: true,
        })
      } else {
        // User is not an admin, block access.
        setCustomErrorMessage("You do not have permission to use this site.")
      }
    }
  }, [data, navigate, setAuthContext])

  return (
    <BaseForm onSubmit={handleSubmit(data => mutate(data))}>
      <FormGroup label="username/email" error={errors.username}>
        <Controller
          name="username"
          control={control}
          render={({ field }) => <FormControl isInvalid={errors.username} {...field} />}
        />
      </FormGroup>
      <FormGroup label="password" error={errors.password}>
        <Controller
          name="password"
          control={control}
          render={({ field }) => <FormControl isInvalid={errors.password} type="password" {...field} />}
        />
      </FormGroup>
      <FormSubmitContainer errorMessage={(isError && errorMessage) || customErrorMessage}>
        <FormSubmitButton disabled={isLoading}>Login</FormSubmitButton>
      </FormSubmitContainer>
    </BaseForm>
  )
}

export default LoginForm
