import { string } from "prop-types";
import styled from "styled-components";

const StyledLink = styled.a`
  text-decoration: none;
`;

function TableLink(props) {
  /** Link to display as data in a table. */
  return <StyledLink target="_blank" rel="noreferrer" {...props}>{props.children}</StyledLink>
}

TableLink.propTypes = {
  href: string.isRequired,
}

export default TableLink;
