import { useParams } from "react-router";

import PageHeader from "components/text/PageHeader";

function ContentUploadDetailRoute(props) {
  /** Display and manage data for a single content upload. */

  const { contentUploadId } = useParams();

  return (
    <div>
      <PageHeader>Detail for {contentUploadId}</PageHeader>
    </div>
  )
}

export default ContentUploadDetailRoute;
