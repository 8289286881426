import { useQuery } from "react-query";

import LoadingIcon from "components/loading/Icon";
import HeaderContainer from "components/containers/Header";
import { fetchSubscriptionCancellations } from "features/subscriptions/service";
import CancellationTable from "features/subscriptions/tables/Cancellations";
import SubscriptionSecondaryNavbar from "features/subscriptions/navigation/Secondary";
import DashboardSecondaryContainer from "components/containers/DashboardSecondary";

function CancellationsRoute() {
  /** Show general data about our subscription cancellations. */
  const { data, isLoading } = useQuery('subscription', fetchSubscriptionCancellations);

  if (isLoading)
    return <LoadingIcon />

  const { results } = data;

  return (
    <DashboardSecondaryContainer>
      <SubscriptionSecondaryNavbar />
      <div className="p-5">
        <HeaderContainer>
          <div className="my-5">
            <h3 className="text-muted">
              Pending Cancellations: <b>{results.filter(c => c.isPending).length}</b>
            </h3>
            <h3 className="text-muted">Current Cancellation Rate: <b>X%</b></h3>
            <h3 className="text-muted">Monthly Reactivation Rate: <b>X%</b></h3>
          </div>
        </HeaderContainer>
        <CancellationTable data={results} />
      </div>
    </DashboardSecondaryContainer>
  )
}

export default CancellationsRoute;
