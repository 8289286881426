import { useQuery } from "react-query";
import styled from "styled-components";

import PageHeader from "components/text/PageHeader";
import LoadingIcon from "components/loading/Icon";
import { fetchAnimaticDonations } from "features/contentuploads/service";
import AnimaticDonationTable from "features/contentuploads/tables/AnimaticDonations";

const TableWrapper = styled.div`
  @media (min-width: ${(props) => props.theme.tabletBreakpoint}) {
    width: 60%;
    margin-left: 20%;
  }
`;

function AnimaticDonationListRoute() {
  /** Display data about donations received by content uploads. */

  const { data: response, isLoading } = useQuery('animatic-donations', fetchAnimaticDonations);

  if (isLoading) return <LoadingIcon />

  return (
    <>
      <PageHeader>Animatic Donations</PageHeader>
      <TableWrapper>
        <AnimaticDonationTable data={response.data} />
      </TableWrapper>
    </>
  )
}

export default AnimaticDonationListRoute;
