import { LineChart, Legend, Line, Tooltip, XAxis, YAxis } from 'recharts';

function SubscriptionLineChart({ data }) {
  /** Show our active subscribers over time. */

  return (
    <LineChart width={600} height={400} data={data}>
      <Line type="monotone" dataKey="active" stroke="#8884d8" />
      <Tooltip />
      <XAxis dataKey="date" />
      <YAxis />
      <Legend />
    </LineChart>
  )
}

export default SubscriptionLineChart;
