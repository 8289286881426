import { useContext } from "react";

import AuthContext from "context/Auth";
import axios from "axios";

const useLogoutUser = (items, config=null) => {
  /** Perform a logout for the user. */
  const { setAuthContext } = useContext(AuthContext);

  function handleLogout() {
    setAuthContext({
      accessToken: "",
      refreshToken: "",
      isAuthenticated: false,
    });

    delete axios.defaults.headers.common['Authorization'];
  }

  return handleLogout;
}

export default useLogoutUser;
