import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import ReactDatePicker from "react-datepicker";
import { useParams } from "react-router";
import { useMutation, useQueryClient } from "react-query";
import { format } from "date-fns";
import { func, object } from "prop-types";

import Button from "components/buttons/Base";
import BaseForm from "components/forms/Base";
import FormGroup from "components/forms/Group";
import FormControl from "components/forms/Control";
import FormSubmitButton from "components/buttons/FormSubmit";
import FormSubmitContainer from "components/forms/SubmitContainer";
import { classPhaseType } from "features/classphases/enums";
import { createClassPhase, updateClassPhase } from "features/classphases/service";
import { handleResponseError } from "utils/form";

function ClassPhaseForm({ closeModal, classPhase, showDeleteConfirmationModal }) {
  /** Form to modify or create a class phase object. */

  const { control, handleSubmit, setError, formState: { errors } } = useForm({
    defaultValues: classPhase || {},
  });
  const { classId } = useParams();
  const queryClient = useQueryClient();

  const isUpdating = classPhase !== null;

  const { mutate, isError, error, isLoading } = useMutation(
    isUpdating ? updateClassPhase : createClassPhase, {
    onSuccess: (data) => {
      /** Update the data after class phase is created or updated. */
      const classData = queryClient.getQueryData(['classes', classId])
      let classPhases = []

      if (isUpdating) {
        classPhases = classData.classPhases.map(classPhase => {
          return classPhase.id === data.id ? data : classPhase
        })
      } else {
        classPhases = [
          ...classData.classPhases,
          data,
        ]
      }

      queryClient.setQueryData(['classes', classId], {
        ...classData,
        classPhases,
      });
      closeModal();
    },
  });

  function _handleSubmit(data) {
    data.type = classPhaseType.custom;
    data.project = classId;
    data.startDate = format(data.startDate, 'yyyy-MM-dd')
    data.endDate = format(data.endDate, 'yyyy-MM-dd')

    let mutationPayload = { payload: data }
    if (isUpdating) mutationPayload.id = classPhase.id

    mutate(mutationPayload);
  }

  useEffect(() => {
    if (isError && error) {
      handleResponseError(error.response.data, () => {}, setError, )
    }
  }, [isError, error, setError])

  return (
    <BaseForm onSubmit={handleSubmit(_handleSubmit)}>
      <FormGroup label="Title" error={errors.title}>
        <Controller
          name="title"
          control={control}
          render={({ field }) => <FormControl isInvalid={errors.title} {...field} />}
          rules={{required: true}}
        />
      </FormGroup>
      <FormGroup label="Start date" error={errors.startDate}>
        <Controller
          name="startDate"
          control={control}
          rules={{required: true}}
          render={({ field }) =>
            <ReactDatePicker
              selected={field.value}
              onChange={field.onChange}
            />
          }
        />
      </FormGroup>
      <FormGroup label="End date" error={errors.endDate}>
        <Controller
          name="endDate"
          rules={{required: true}}
          control={control}
          render={({ field }) =>
            <ReactDatePicker
              selected={field.value}
              onChange={field.onChange}
            />
          }
        />
      </FormGroup>
      <FormSubmitContainer>
        <Button variant="link" className="text-danger" onClick={showDeleteConfirmationModal}>
          Delete
        </Button>
        <FormSubmitButton disabled={isLoading}>Save</FormSubmitButton>
      </FormSubmitContainer>
    </BaseForm>
  )
}

ClassPhaseForm.propTypes = {
  /** Action to close the parent modal. */
  closeModal: func.isRequired,

  /** Class phase object to modify. */
  classPhase: object,

  /** Open the modal to confirm deletion of a class phase. */
  showDeleteConfirmationModal: func,
}

ClassPhaseForm.defaultProps = {
  classPhase: null,
}

export default ClassPhaseForm
