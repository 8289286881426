import { bool, object, shape, string } from "prop-types";
import styled from "styled-components";
import { format } from 'date-fns-tz'

import BaseTable from "components/tables/Base";
import { appUrl } from "utils/general";

const StyledLink = styled.a`
  text-decoration: none;
`;

function CancellationTable({ data, ...props }) {
  /** Show raw data of our subscription trend data. */

  const BUILD_ENV = process.env.REACT_APP_BUILD_ENV || "local";

  const dashboardSuffix = BUILD_ENV === 'prod' ?
    "" :
    "test/";

  return (
    <BaseTable {...props}>
      <thead>
        <tr>
          <th>User</th>
          <th>Cancellation Date</th>
          <th>Is Pending</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {data.map(cancellation => (
          <tr key={cancellation.id}>
            <td>
              {cancellation.user?.username && (
                <StyledLink href={`${appUrl}users/${cancellation.user.username}`} target="_blank">
                  @{cancellation.user.username}
                </StyledLink>
              )}
            </td>
            <td>{cancellation?.cancellationDate && format(new Date(cancellation.cancellationDate), 'MM/dd/yyyy')}</td>
            <td>{cancellation.isPending ? "yes" : "no"}</td>
            <td><StyledLink href={`https://dashboard.stripe.com/${dashboardSuffix}subscriptions/${cancellation.subscriptionId}`}>View in Stripe</StyledLink></td>
          </tr>
        ))}
      </tbody>
    </BaseTable>
  )
}

CancellationTable.propTypes = {
  data: shape({
    user: object.isRequired,
    cancellationDate: string.isRequired,
    isPending: bool.isRequired,
  })
}

export default CancellationTable;
