import { useParams } from "react-router";
import { useQuery } from "react-query";
import styled from "styled-components";

import LoadingIcon from "components/loading/Icon";
import SectionHeader from "components/text/SectionHeader";
import { fetchClass, fetchClassEarnings } from "features/classes/service";
import TierSummaryTable from "features/tiers/tables/Summary";
import EarningsSummaryTable from "features/classes/tables/Earnings";
import { appUrl } from "utils/general";
import ClassPhases from "features/classphases/components/Phases";
import Button from "components/buttons/Base";
import { useState } from "react";
import ClassPhaseModal from "features/classphases/modals/Phase";

const SummarySection = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  margin-top: 50px;
`;

function ClassDetailRoute() {
  /** Show summary information about a given class. */

  const { classId } = useParams();
  const { data, isLoading } = useQuery(['classes', classId], () => fetchClass(classId));
  const { data: earningsData, isLoading: earningsIsLoading } = useQuery(
    ['earnings', classId], () => fetchClassEarnings(classId)
  );
  const [classPhaseModalOpen, setClassPhaseModalOpen] = useState(false);

  if (isLoading) return <LoadingIcon />

  const { title, teachers, tiers } = data;

  function renderTeachers() {
    return teachers.map(teacher => {
      const { firstName, lastName, username } = teacher;
      const link = `${appUrl}users/${username}` ;

      return (
        <p className="mb-0" key={teacher.id}>
          {firstName} {lastName} (<a className="text-decoration-none" href={link} target="_blank" rel="noreferrer">@{username}</a>)
        </p>
      )
    })
  }

  return (
    <div>
      <h1 className="text-center">{title}</h1>
      <SummarySection>
        <div>
          <SectionHeader>Teachers</SectionHeader>
          {renderTeachers()}
        </div>
        <div>
          <SectionHeader>Earnings Summary</SectionHeader>
          {earningsIsLoading ? <LoadingIcon /> : <EarningsSummaryTable data={earningsData} />}
        </div>
        {data.classPhases?.length > 0 && (
          <div>
            <SectionHeader>Phases</SectionHeader>
            <ClassPhases classPhases={data.classPhases} />
            <Button onClick={() => setClassPhaseModalOpen(true)}>Create New</Button>
          </div>
        )}
      </SummarySection>
      <div className="mt-5">
        <SectionHeader>Registration Tiers</SectionHeader>
        <div>
          <TierSummaryTable data={tiers} />
        </div>
      </div>
      <ClassPhaseModal
        isOpen={classPhaseModalOpen}
        onRequestClose={() => setClassPhaseModalOpen(false)}
        title="Create class phase"
      />
    </div>
  )
}

export default ClassDetailRoute;
