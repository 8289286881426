import PageHeader from "components/text/PageHeader";
import React from "react";

function EventListRoute() {
  /** Display and manage data for a list of events. */

  return (
    <div>
      <PageHeader>Events</PageHeader>
      <p>Coming soon&trade;</p>
    </div>
  )
}

export default EventListRoute;
