import { number, shape, string } from "prop-types";

import BaseTable from "components/tables/Base";

function RevenueRetentionTable({ data, ...props }) {
  /** Table showing the data for all the annual subscription revenue retention. */

  return (
    <BaseTable {...props}>
      <thead>
        <tr>
          <th>Subscription</th>
          <th>Progress</th>
          <th>Expected Rention</th>
        </tr>
      </thead>
      <tbody>
        {data.map(sub => (
          <tr key={sub.id}>
            <td>
              <a href={`https://dashboard.stripe.com/subscriptions/${sub.subscription}`} target="_blank">
                {sub.subscription}
              </a>
            </td>
            <td>{sub.progress}%</td>
            <td>${parseFloat(sub.retentionAmount)}</td>
          </tr>
        ))}
      </tbody>
    </BaseTable>
  )
}

RevenueRetentionTable.propTypes = {
  data: shape({
    id: number.isRequired,
    subscription: string.isRequired,
    progress: number.isRequired,
    retentionAmount: string.isRequired,
  })
}

export default RevenueRetentionTable;
