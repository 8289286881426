import { useQuery } from "react-query";
import styled from "styled-components";

import StatSection from "components/containers/StatSection";
import DashboardSecondaryContainer from "components/containers/DashboardSecondary";
import LoadingIcon from "components/loading/Icon";
import SubscriptionSecondaryNavbar from "features/subscriptions/navigation/Secondary";
import { fetchAnnualRevenueRetention } from "features/subscriptions/service";
import RevenueRetentionTable from "features/subscriptions/tables/RevenueRetention";

const StatContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
`;

function RevenueRetentionRoute() {
  /** Display data about how much revenue should be retained for each annual subscription. */
  const { data, isLoading } = useQuery('annual-revenue-retention', fetchAnnualRevenueRetention);

  if (isLoading) return <LoadingIcon />

  const totalRetention = data.reduce((a,b) => {
    return a + parseFloat(b["retentionAmount"]);
  }, 0);

  return (
    <DashboardSecondaryContainer>
      <SubscriptionSecondaryNavbar />
      <div className="p-5">
        <StatContainer>
          <StatSection top={`$${totalRetention.toFixed(2)}`} bottom="Total expected retention" />
        </StatContainer>
        <RevenueRetentionTable data={data}/>
      </div>
    </DashboardSecondaryContainer>
  )
}

export default RevenueRetentionRoute;
