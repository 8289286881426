import BaseTable from "components/tables/Base";
import { toCurrency } from "utils/general";

function EarningsSummaryTable({ data }) {
  /** Display data about a project's earnings (and its tiers). */
  function renderRows() {
    const tierData = data.map(tier => {
      const { registrationTier, registrationTierId, total } = tier;

      return (
        <tr key={registrationTierId}>
          <td>{registrationTier}</td>
          <td>{toCurrency(total)}</td>
        </tr>
      )
    })

    return tierData
  }

  const total = data.reduce((sum, current) => {
    return sum + current.total;
  }, 0);

  return (
    <BaseTable>
      <thead>
        <tr>
          <th>title</th>
          <th>total</th>
        </tr>
      </thead>
      <tbody>
        {renderRows()}
        <tr className="fw-bold">
          <td>Class Total</td>
          <td>{toCurrency(total)}</td>
        </tr>
      </tbody>
    </BaseTable>
  )
}

export default EarningsSummaryTable;
